<template>
    <div class="row">
        <div class="col-12 mb-4">
            <div class="card pt-4">
                <div class="card-header border-0">
                    <div class="card-title">
                        <h2 class="fw-bolder">{{ $t("pages.roadmap.title") }}</h2>
                    </div>
                    <div class="align-items-end flex-column">
                        <!--<router-link to="/roadmap/related-roadmap/">
                            <a class="btn btn-primary btn-sm btn-light btn-active-light-primary me-3">{{ $t("pages.roadmap.perimeterRoadmap") }}</a>
                        </router-link>-->

                        <a @click="this.downloadRoadmap" class="btn btn-primary btn-sm btn-light btn-active-light-primary me-3"> {{ $t("general.download") }} </a>
                        <router-link v-slot="{ href, navigate }" to="/add-roadmap">
                            <a class="btn btn-primary btn-sm btn-light btn-active-light-primary" :href="href" @click="navigate">
                                <span class="menu-title">{{ $t("pages.roadmap.addAction") }}</span>
                            </a>
                        </router-link>
                    </div>
                </div>
                <div class="card-body pt-0">
                    <div class="fw-bolder fs-2">
                        <div class="fs-7 fw-normal text-muted">
                            {{ $t("pages.roadmap.desc") }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="alert alert-primary" role="alert">
                <div class="d-flex justify-content-between">
                    <p class="pt-4">{{$t("pages.roadmap.roadmapToIdeabox")}} </p>
                    <button class="btn btn-primary btn-sm" @click="$router.push({ name: 'ideas' })">{{$t("pages.roadmap.toIdeaBox")}}</button>
                </div>

            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <div class="card pt-4 mb-xl-9">
                <div class="card-body">
                    <div class="table-responsive" id="printableTable">
                        <table class="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3">
                            <thead>
                                <tr class="fw-bolder text-muted">
                                    <th class="min-w-120px">{{ $t("pages.roadmap.action") }}</th>
                                    <th class="min-w-120px">{{ $t("pages.roadmap.deadline") }}</th>
                                    <th class="min-w-120px">{{ $t("pages.roadmap.contributor") }}</th>
                                    <th class="min-w-120px">{{ $t("pages.roadmap.impact") }}</th>
                                    <th class="min-w-120px">{{ $t("pages.roadmap.status") }}</th>
                                    <th class="min-w-120px text-center">Notes</th>
                                    <th class="min-w-120px text-end"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="action in roadmaps" v-bind:key="action.id">
                                    <td>
                                      <span class="text-muted fw-bold text-muted d-block fs-7">{{ action.category.label }}</span>
                                        <a href="#" class="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6"> {{ action.label }}</a>
                                    </td>
                                    <td class="text-dark fw-bolder text-hover-primary mb-1 fs-6">
                                        {{ $moment(action.deadline).format("DD/MM/YYYY") }}
                                    </td>
                                    <td class="text-dark fw-bolder text-hover-primary mb-1 fs-6">
                                        {{ action.collaborators }}
                                    </td>
                                    <td class="text-dark fw-bolder text-hover-primary mb-1 fs-6">
                                        {{ action.impact }}
                                    </td>
                                    <td>
                                        <span v-if="action.status === 'TODO'" class="badge-light-primary badge text-default">{{ $t("pages.roadmap.todo") }}</span>
                                        <span v-else-if="action.status === 'DONE'" class="badge-light-success badge text-success">{{ $t("pages.roadmap.done") }}</span>
                                        <span v-else-if="action.status === 'INPR'" class="badge-light-warning badge text-warning">{{ $t("pages.roadmap.inProgress") }}</span>
                                    </td>
                                    <td class="text-dark fw-bolder mb-1 fs-6 text-center">
                                        <span v-if="action.commentary === ''"></span>
                                        <a href="#" data-bs-toggle="modal" :data-bs-target="'#commentModal' +action.pk " v-else>
                                          <i class="far fa-eye text-primary fs-4"></i>
                                        </a>

                                        <!-- Modal -->
                                        <div class="modal fade" :id="'commentModal' + action.pk" tabindex="-1" aria-labelledby="commentModalLabel" aria-hidden="true">
                                            <div class="modal-dialog modal-dialog-centered">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h5 class="modal-title" id="commentModalLabel">{{ action.label }} - {{ $t("pages.roadmap.commentaries") }}</h5>
                                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                    </div>
                                                    <div class="modal-body fw-normal">
                                                        {{ action.commentary }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="text-end">
                                        <a @click="editRoadmap(action)" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                                            <span class="svg-icon svg-icon-3"
                                                ><svg fill="none" viewBox="0 0 24 24" height="24" width="24" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        opacity="0.3"
                                                        d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z"
                                                        fill="black"
                                                    ></path>
                                                    <path
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z"
                                                        fill="black"
                                                    ></path></svg></span
                                        ></a>
                                        <a @click="this.deleteAction(action)" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                                            ><span class="svg-icon svg-icon-3"
                                                ><svg fill="none" viewBox="0 0 24 24" height="24" width="24" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z"
                                                        fill="black"
                                                    ></path>
                                                    <path
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        opacity="0.5"
                                                        d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z"
                                                        fill="black"
                                                    ></path>
                                                    <path
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        opacity="0.5"
                                                        d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z"
                                                        fill="black"
                                                    ></path></svg></span
                                        ></a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import roadMapApi from "@/core/services/RoadMap";
import ExcelJS from 'exceljs';
import { useI18n } from "vue-i18n";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useRouter } from "vue-router";

export default defineComponent({
    name: "roadmap",
    components: {},
    data: function () {
        return {
            action: {
                id: 0,
                label: "",
                collaborators: "",
                deadline: "",
                category: 0,
                status: "TODO",
                commentary: "",
                impact: "",
            },
            roadmaps: [],
            categories: [],
        };
    },
    methods: {
        downloadRoadmap() {
            if (this.roadmaps && this.roadmaps.length > 0) {
                // Format data to match the desired structure
                const formattedData = this.roadmaps.map((action: any) => {
                    let status = "A faire";
                    if (action.status === "DONE") {
                        status = "Terminée";
                    } else if (action.status === "INPR") {
                        status = "En cours";
                    }
                    return {
                        Thématique: action.category.label,
                        Action: action.label,
                        Contributeurs: action.collaborators,
                        Échéance: action.deadline,
                        Statut: status,
                        Commentaires: action.commentary,
                    };
                });

                // Create a new workbook and worksheet
                const workbook = new ExcelJS.Workbook();
                const worksheet = workbook.addWorksheet("Roadmaps");

                // Define columns with headers and data keys
                worksheet.columns = [
                    { header: "Thématique", key: "Thématique", width: 20 },
                    { header: "Action", key: "Action", width: 30 },
                    { header: "Contributeur(s)", key: "Contributeurs", width: 25 },
                    { header: "Échéance", key: "Échéance", width: 15 },
                    { header: "Statut", key: "Statut", width: 15 },
                    { header: "Commentaires", key: "Commentaires", width: 30 },
                ];

                // Add each data row to the worksheet
                formattedData.forEach((row) => {
                    worksheet.addRow(row);
                });

                // Apply styling to the header row
                worksheet.getRow(1).eachCell((cell) => {
                    cell.font = { bold: true };
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'ADD8E6' }, // Light blue background color
                    };
                    cell.alignment = { vertical: 'middle', horizontal: 'center' };
                });

                // Export the workbook as an Excel file
                workbook.xlsx.writeBuffer().then((buffer) => {
                    const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement("a");
                    a.href = url;
                    a.download = "roadmaps.xlsx";
                    a.click();
                    window.URL.revokeObjectURL(url);
                });
            }
        },
        deleteAction(action) {
            roadMapApi
                .deleteAction(action.pk)
                .then(() => {
                    location.reload();
                })
                .catch(() => {
                    this.displayErrorMsg("Une erreur est survenue lors de la suppression de l'action.");
                });
        },
        getRoadMaps() {
            roadMapApi
                .getRoadMaps()
                .then((response) => {
                    this.roadmaps = response.data["roadmaps"];
                })
                .catch(() => {
                    this.displayErrorMsg("Une erreur est survenue lors de la récupération des plans d'action.");
                });
        },
        displayErrorMsg(msg: string) {
            Swal.fire({
                text: msg,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Fermer",
                customClass: {
                    confirmButton: "btn fw-bold btn-light-danger",
                },
            });
        },
    },
    setup() {
        const router = useRouter();
        const { t } = useI18n();
        setCurrentPageBreadcrumbs(t("pages.roadmap.title"), []);

        function editRoadmap(this: any, action) {
            this.action.label = action.label;
            this.action.collaborators = action.collaborators;
            this.action.deadline = action.deadline;
            this.action.commentary = action.commentary;
            this.action.category = action.category.pk;
            this.action.status = action.status;
            this.action.id = action.pk;
            this.action.impact = action.impact;

            router.push({
                name: "add-roadmap",
                query: {
                    id: this.action.id,
                    label: this.action.label,
                    theme: this.action.category,
                    collaborators: this.action.collaborators,
                    category: this.action.category,
                    deadline: this.action.deadline,
                    impact: this.action.impact,
                    status: this.action.status,
                    commentary: this.action.commentary
                },
            });
        }
        return { t, editRoadmap };
    },
    watch: {
        "$i18n.locale": function () {
            setCurrentPageBreadcrumbs(this.t("pages.roadmap.title"), []);
        },
    },
    created() {
        this.getRoadMaps();
    },
});
</script>
