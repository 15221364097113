
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Form, Field } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import apiUserProfile from "@/core/services/UserProfile";
import apiDrive from "@/core/services/Drive";
import router from "@/router";
import buttonHandle from "@/shared.js";
import { useI18n } from "vue-i18n";

function getBase64(file) {
    return new Promise((resolve, reject) => {
        if (file === undefined) {
            return resolve(null);
        } else {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        }
    });
}

export default defineComponent({
    name: "administration-add-documents",
    components: {
        Form,
        Field,
    },
    data() {
        return {
            document: {
                id: "",
                label: "",
                label_en: "",
                category: -1,
                document: "",
                document_en: "",
                video: "",
                video_en: "",
                user_types: [] as any,
                filetype: "",
                order: 1,
                picto: "infography",
            },
            illustrations: [
                {
                    label: "Planning",
                    src: "/media/illustrations/documents/planning.svg",
                    class: "",
                },
                {
                    label: "Check List",
                    src: "/media/illustrations/documents/checkList.svg",
                    class: "",
                },
                {
                    label: "Check List 2",
                    src: "/media/illustrations/documents/checkList2.svg",
                    class: "",
                },
                {
                    label: "Process",
                    src: "/media/illustrations/documents/process.svg",
                    class: "",
                },
                {
                    label: "Vidéo 1",
                    src: "/media/illustrations/documents/video.svg",
                    class: "",
                },
                {
                    label: "Vidéo 2",
                    src: "/media/illustrations/documents/video2.svg",
                    class: "",
                },
                {
                    label: "Replay Webinar",
                    src: "/media/illustrations/documents/replayWebinar.svg",
                    class: "",
                },
                {
                    label: "Tutoriel",
                    src: "/media/illustrations/documents/tutoriel.svg",
                    class: "",
                },
                {
                    label: "Appropriation résultats",
                    src: "/media/illustrations/documents/approResults.svg",
                    class: "",
                },
                {
                    label: "Appropriation résultats 2",
                    src: "/media/illustrations/documents/approResults2.svg",
                    class: "",
                },
                {
                    label: "Guide 1",
                    src: "/media/illustrations/documents/guide.svg",
                    class: "",
                },
                {
                    label: "Guide 2",
                    src: "/media/illustrations/documents/guide2.svg",
                    class: "",
                },
                {
                    label: "Guide 3",
                    src: "/media/illustrations/documents/guide3.svg",
                    class: "",
                },
                {
                    label: "Guide 4",
                    src: "/media/illustrations/documents/guide4.svg",
                    class: "",
                },
                {
                    label: "Guide anonymat",
                    src: "/media/illustrations/documents/guideAnonymat.svg",
                    class: "",
                },
                {
                    label: "Q&A 1",
                    src: "/media/illustrations/documents/QA.svg",
                    class: "",
                },
                {
                    label: "Q&A 2",
                    src: "/media/illustrations/documents/QA2.svg",
                    class: "",
                },
                {
                    label: "Guide plateforme",
                    src: "/media/illustrations/documents/guidePlateforme.svg",
                    class: "",
                },
                {
                    label: "Guide plans d'actions",
                    src: "/media/illustrations/documents/guideRoadmap.svg",
                    class: "",
                },
            ],
            selectedIllustration: this.t("pages.driveManagement.documents.noIllustration"),
            finalIllustration: "",
            finalSrc: "",
            userTypes: [],
            documentCategories: [],
        };
    },
    setup() {
        const { t } = useI18n();
        const submitButton = ref<HTMLElement | null>(null);
        onMounted(() => {
            setCurrentPageBreadcrumbs(t("pages.driveManagement.documents.addDoc"), [
                {
                    link: "/administration/documents/documents",
                    label: t("pages.driveManagement.documents.title"),
                },
            ]);
        });
        return {
            t,
            submitButton,
        };
    },
    watch: {
        "$i18n.locale": function () {
            setCurrentPageBreadcrumbs(this.t("pages.driveManagement.documents.addDoc"), [
                {
                    link: "/administration/documents/documents",
                    label: this.t("pages.driveManagement.documents.title"),
                },
            ]);
            this.selectedIllustration = this.t("pages.driveManagement.documents.noIllustration");
        },
    },
    created() {
        this.fetchCategories();
        this.fetchUserTypes();
        if (this.$route.query.id) {
            apiDrive.getDocument(this.$route.query.id).then((data) => {
                let document = data.document;
                let pictoLbl;

                for (let i = 0; i < this.illustrations.length; i++) {
                    if (document.picto === this.illustrations[i].src) {
                        pictoLbl = this.illustrations[i].label;
                        this.selectedIllustration = pictoLbl;
                        this.illustrations[i].class = "selected";
                    }
                }
                this.document = {
                    id: document.id,
                    label: document.label,
                    label_en: document.label_en,
                    category: document.category.id,
                    filetype: document.filetype,
                    document: document.document,
                    video: document.video,
                    video_en: document.video_en,
                    document_en: document.document_en,
                    user_types: document.user_types.map((userType) => userType.id),
                    order: document.order,
                    picto: document.picto,
                };
                this.finalIllustration = pictoLbl;
            });
        }
    },
    methods: {
        onSubmit(e) {
            e.preventDefault();
            if (this.validateForm() && this.$route.query.id) {
                buttonHandle.handleWaitingButton(this.submitButton);
                apiDrive
                    .editDocument(this.document.id, this.document)
                    .then(() => {
                        buttonHandle.handleValidatedButton(this.submitButton);
                        setTimeout(() => {
                            router.push({ name: "administration-documents" });
                        }, 500);
                    })
                    .catch(() => {
                        this.displayErrorMsg("Une erreur est survenue. Contactez l'administrateur du site.");
                    });
            } else if (this.validateForm()) {
                buttonHandle.handleWaitingButton(this.submitButton);
                this.document.picto = this.finalSrc;
                if (this.document.video !== "") {
                    this.document.filetype = "video";
                }
                apiDrive
                    .addDocument(this.document)
                    .then(() => {
                        buttonHandle.handleValidatedButton(this.submitButton);
                        setTimeout(() => {
                            router.push({ name: "administration-documents" });
                        }, 500);
                    })
                    .catch(() => {
                        this.displayErrorMsg("Une erreur est survenue. Contactez l'administrateur du site.");
                    });
            }
        },
        validateForm() {
            if (this.document.category && this.document.category < 1) {
                this.displayErrorMsg(this.t("pages.driveManagement.documents.categoryValidator"));
            } else if (this.document.user_types && this.document.user_types.length === 0) {
                this.displayErrorMsg(this.t("pages.driveManagement.documents.targetValidator"));
            } else if (this.document.label === "") {
                this.displayErrorMsg(this.t("pages.driveManagement.documents.nameValidator"));
            } else if ((!this.document.document && !this.$route.query.id) && (!this.document.video && !this.$route.query.id)) {
                this.displayErrorMsg(this.t("pages.driveManagement.documents.fileValidator"));
            } else if (this.finalIllustration === "") {
                this.displayErrorMsg(this.t("pages.driveManagement.documents.illustrationValidator"));
            } else {
                return true;
            }
        },
        addFilesFr(e: any) {
            let documentFr = e.target.files[0];
            this.document.filetype = documentFr.type;
            getBase64(documentFr).then((base64Document: string) => {
                this.document.document = base64Document;
            });
        },
        addFilesEn(e: any) {
            let documentEn = e.target.files[0];
            getBase64(documentEn).then((base64Document: string) => {
                this.document.document_en = base64Document;
            });
        },
        fetchCategories() {
            apiDrive.getCategories().then((response) => {
                this.documentCategories = response.data.categories;
            });
        },
        fetchUserTypes() {
            apiUserProfile.getUserTypes().then((response) => {
                this.userTypes = response.data.usertypes;
            });
        },
        selectImg(img) {
            if (this.illustrations) {
                for (let i = 0; i < this.illustrations.length; i++) {
                    this.illustrations[i].class = "";
                }
                img.class = "selected";
                this.finalIllustration = this.selectedIllustration = img.label;
                this.document.picto = this.finalSrc = img.src;
            }
        },
        displayErrorMsg(msg: string) {
            Swal.fire({
                text: msg,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Fermer",
                customClass: {
                    confirmButton: "btn fw-bold btn-light-danger",
                },
            });
        },
    },
});
