<template>
    <div class="mb-5 mb-xl-8 card">
        <div class="card-header border-0 pt-5">
            <h3 class="card-title align-items-start flex-column">
                <span class="card-label fw-bolder fs-3 mb-1">{{ $t("pages.users.title") }}</span>
                <span class="text-muted mt-1 fw-bold fs-7">{{ this.$store.getters.currentSettings.clientName }}</span>
            </h3>
            <div class="align-items-end flex-column">
                <a href="#" class="btn btn-primary btn-light" data-bs-toggle="modal" data-bs-target="#kt_modal_1">{{ $t("pages.users.addUsers") }}</a>
            </div>
        </div>
        <div class="card-body py-3">
            <div class="p-5 mt-5 mb-5 bg-primary bg-opacity-25 rounded">
                <div class="row">
                    <div class="col">
                        <input
                            v-model="filter.identity"
                            type="text"
                            name="fname"
                            class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                            :placeholder="$t('pages.users.name')"
                        />
                    </div>
                    <!--end::Col-->

                    <!--begin::Col-->
                    <div class="col">
                        <input v-model="filter.email" type="text" name="lname" class="form-control form-control-lg form-control-solid" :placeholder="$t('pages.users.email')" />
                    </div>
                    <div class="col">
                        <select v-model="filter.user_type" class="form-control form-control-solid">
                            <option disabled selected value>{{ $t("pages.users.userType") }}</option>
                            <option name="pilote" value="pilote">Pilote</option>
                            <option name="network" value="network">Réseau baromètre</option>
                            <option name="manager" value="manager">Manager</option>
                        </select>
                    </div>
                    <div class="col">
                        <select v-model="filter.status" class="form-control form-control-solid">
                            <option disabled selected value>{{ $t("pages.users.status") }}</option>
                            <option name="active" value="ACT">{{ $t("pages.users.active") }}</option>
                            <option name="disabled" value="DEA">{{ $t("pages.users.disabled") }}</option>
                            <option name="disabled" value="INV">{{ $t("pages.users.invited") }}</option>
                        </select>
                    </div>
                    <div class="col">
                        <button type="submit" class="btn btn-primary btn-light" @click="fetchUsers()">
                            {{ $t("general.search") }}
                        </button>
                        <button type="submit" class="ms-3 btn btn-icon btn-light btn-active-light-primary" @click="resetFilter()">
                            <i class="fas fa-redo fs-7 pt-1"></i>
                        </button>
                    </div>
                    <!--end::Col-->
                </div>
                <!--end::Row-->
            </div>
            <!--end::Col-->

            <div class="table-responsive">
                <table class="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3">
                    <thead>
                        <tr class="fw-bolder text-muted">
                            <th class="min-w-150px">{{ $t("pages.users.name") }}</th>
                            <th class="min-w-120px">{{ $t("pages.users.email") }}</th>
                            <th class="min-w-120px">{{ $t("pages.users.accessLevel") }}</th>
                            <th class="min-w-120px">{{ $t("pages.users.lastConnection") }}</th>
                            <th class="min-w-120px">{{ $t("pages.users.status") }}</th>
                            <th class="min-w-100px text-end">{{ $t("pages.users.actions") }}</th>
                        </tr>
                    </thead>
                    <tbody v-if="!loading">
                        <tr v-for="user in this.users" v-bind:key="user.id">
                            <td>
                                <a href="#" class="text-dark fw-bolder text-hover-primary fs-6">{{ user.userprofile.firstname }}</a>
                                <span class="text-muted fw-bold text-muted d-block fs-7">{{ user.userprofile.lastname }}</span>
                            </td>
                            <td>
                                <a href="#" class="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6"> {{ user.email }} </a>
                            </td>
                            <td>
                                <span class="badge-light badge text-primary">{{ user.userprofile.user_type.label }}</span>
                            </td>
                            <td v-if="user.last_connected && user.last_connected.date_created">
                                <a href="#" class="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">{{ $moment(user.last_connected.date_created).format("DD/MM/YYYY") }}</a>
                                <span class="text-muted fw-bold text-muted d-block fs-7">{{ $moment(user.last_connected.date_created).format("HH:mm") }}</span>
                            </td>
                            <td v-else>
                                <span class="text-muted fw-bold text-muted d-block fs-7">-</span>
                            </td>
                            <td>
                                <span v-if="user.status === 'ACT'" class="badge-light-success badge text-success">Actif</span>
                                <span v-if="user.status === 'INV'" class="badge-light-primary badge text-primary">Invité</span>
                            </td>
                            <td class="text-end">
                                <a @click="editUser(user)" href="#" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                                    <span class="svg-icon svg-icon-3"
                                        ><svg fill="none" viewBox="0 0 24 24" height="24" width="24" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                xmlns="http://www.w3.org/2000/svg"
                                                opacity="0.3"
                                                d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z"
                                                fill="black"
                                            ></path>
                                            <path
                                                xmlns="http://www.w3.org/2000/svg"
                                                d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z"
                                                fill="black"
                                            ></path></svg></span
                                ></a>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div v-if="loading">
                    <div class="d-flex row-auto flex-center w-100 h-200px">
                        <span class="spinner-border text-primary" role="status"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" tabindex="-1" id="kt_modal_1">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t("pages.users.addUsers") }}</h5>

                    <!--begin::Close-->
                    <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close">
                        <span class="svg-icon svg-icon-2x"></span>
                    </div>
                    <!--end::Close-->
                </div>

                <div class="modal-body">
                    <form id="kt_add_action_form" class="form">
                        <div class="row mb-6">
                            <!--begin::Label-->
                            <label class="col-lg-12 col-form-label required fw-bold fs-6"> {{ $t("pages.users.file") }} </label>
                            <!--end::Label-->
                            <!--begin::Col-->
                            <div class="col-lg-12">
                                <!--begin::Row-->
                                <div class="row">
                                    <!--begin::Col-->
                                    <div class="col-lg-12 fv-row">
                                        <input @change="handleFile" type="file" name="avatar" accept=".xlsx" />
                                    </div>
                                    <!--end::Col-->
                                </div>
                                <!--end::Row-->
                            </div>
                            <!--end::Col-->
                        </div>
                    </form>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-light" data-bs-dismiss="modal">{{ $t("general.close") }}</button>
                    <!-- <button type="button" class="btn btn-primary" @click="addUsers">{{ $t("pages.users.addUsers") }}</button> -->
                    <button type="button" @click="addUsers" id="kt_account_profile_details_submit" ref="submitButton" class="btn btn-primary">
                        <span class="indicator-label"> {{ $t("pages.users.addUsers") }} </span>
                        <span class="indicator-progress">
                            {{ $t("general.pleaseWait") }}
                            <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                        <span class="indicator-validate">
                            {{ $t("general.done") }}
                            <span class="fas fa-check f-5 ms-3"></span>
                        </span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import XLSX from "xlsx";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import userApi from "@/core/services/User";
import { useI18n } from "vue-i18n";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useRouter } from "vue-router";
import buttonHandle from "@/shared.js";

export default defineComponent({
    name: "users",
    components: {},
    data: function () {
        return {
            users: [],
            filter: {
                identity: "",
                email: "",
                user_type: "",
                status: "",
            },
            userFileData: [],
            loading: true,
        };
    },
    methods: {
        handleFile(e) {
            const selectedFile = e.target.files[0];
            const reader = new FileReader();
            reader.onload = (ev) => {
                if (ev.target) {
                    const bstr = ev.target.result;
                    const wb = XLSX.read(bstr, { type: "binary" });
                    const wsname = wb.SheetNames[0];
                    const ws = wb.Sheets[wsname];
                    this.userFileData = XLSX.utils.sheet_to_json(ws, { header: 2 });
                }
            };
            reader.readAsBinaryString(selectedFile);
        },
        addUsers() {
            buttonHandle.handleWaitingButton(this.submitButton);
            userApi
                .addUsers({ users: this.userFileData })
                .then(() => {
                    buttonHandle.handleValidatedButton(this.submitButton);
                    setTimeout(() => {
                        this.fetchUsers();
                    }, 500);
                })
                .catch(() => {
                    buttonHandle.handleErrorButton(this.submitButton);
                    this.displayErrorMsg("Une erreur est survenue lors de la mise à jour des utilisateurs.");
                });
        },
        resetFilter() {
            this.filter = {
                identity: "",
                email: "",
                user_type: "",
                status: "",
            };
            this.fetchUsers();
        },
        fetchUsers() {
            this.loading = true;
            userApi
                .getUsers(this.filter)
                .then((response) => {
                    this.users = response.data["users"];
                    this.loading = false;
                })
                .catch(() => {
                    this.displayErrorMsg("Une erreur est survenue lors de la récupération des utilisateurs.");
                });
        },
        displayErrorMsg(msg: string) {
            Swal.fire({
                text: msg,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Fermer",
                customClass: {
                    confirmButton: "btn fw-bold btn-light-danger",
                },
            });
        },
    },

    setup() {
        const { t } = useI18n();
        const router = useRouter();
        const submitButton = ref<HTMLElement | null>(null);
        setCurrentPageBreadcrumbs(t("pages.dashboard.users"), []);

        function editUser(user: any) {
            userApi.getSpecificUser(user.id).then(() => {
                router.push({ name: "usersdetail", params: { user_id: user.id }, query: { admin: "false" } });
            });
        }
        return { t, editUser, submitButton };
    },
    watch: {
        "$i18n.locale": function () {
            setCurrentPageBreadcrumbs(this.t("pages.dashboard.users"), []);
        },
    },
    created() {
        this.fetchUsers();
    },
});
</script>
