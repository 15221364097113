
import { defineComponent, ref } from "vue";
import XLSX from "xlsx";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import userApi from "@/core/services/User";
import { useI18n } from "vue-i18n";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import xlsx from "json-as-xlsx";
import moment from "moment";

function getLevelCompany(company, level) {
    if (company.position === level) {
        return company.name;
    } else if (company.father) {
        return getLevelCompany(company.father, level);
    } else return "";
}

export default defineComponent({
    name: "users",
    components: {},
    data: function () {
        return {
            users: [],
            totalUsers: 0,
            connectedUser: 0,
            filter: {
                identity: "",
                email: "",
                user_type: "manager",
                status: "",
            },
            userFileData: [],
            loading: true,
            chartOptions: {
                chart: {
                    fontFamily: "inherit",
                    height: 150,
                    type: "radialBar",
                },
                plotOptions: {
                    radialBar: {
                        hollow: {
                            margin: 0,
                            size: "65%",
                        },
                        dataLabels: {
                            showOn: "always",
                            name: {
                                show: false,
                                fontWeight: "700",
                            },
                            value: {
                                color: "#009EF7",
                                fontSize: "30px",
                                fontWeight: "700",
                                offsetY: 12,
                                show: true,
                                formatter: function (val) {
                                    return val + "%";
                                },
                            },
                        },
                        track: {
                            background: "#F5F8FA",
                            strokeWidth: "100%",
                        },
                    },
                },
                colors: ["#009EF7"],
                stroke: {
                    lineCap: "round",
                },
                labels: ["Progress"],
            },
            series: [] as any,
        };
    },
    methods: {
        downloadUsers() {
            let data = [
                {
                    sheet: "Extraction Utilisateurs",
                    columns: [
                        {
                            label: "Nom",
                            value: (row) => row.userprofile.firstname,
                        },
                        {
                            label: "Prénom",
                            value: (row) => row.userprofile.lastname,
                        },

                        {
                            label: "Email",
                            value: (row) => row.email,
                        },
                        {
                            label: "Status",
                            value: (row) => {
                                let status = "Actif";
                                if (row.status === "INV") {
                                    status = "Non Actif";
                                }
                                return status;
                            },
                        },
                        {
                            label: "Dernière connexion",
                            value: (row) => {
                                if (row.last_connected) {
                                    return moment(row.last_connected.date_created).format("DD/MM/YYYY");
                                } else return "Aucune connexion";
                            },
                        },
                        {
                            label: "N1",
                            value: (row) => {
                                if (row.userprofile) {
                                    return getLevelCompany(row.userprofile.related_companies[0], 1);
                                }
                                return "";
                            },
                        },
                        {
                            label: "N2",
                            value: (row) => {
                                if (row.userprofile) {
                                    return getLevelCompany(row.userprofile.related_companies[0], 2);
                                }
                                return "";
                            },
                        },
                        {
                            label: "N3",
                            value: (row) => {
                                if (row.userprofile) {
                                    return getLevelCompany(row.userprofile.related_companies[0], 3);
                                }
                                return "";
                            },
                        },
                        {
                            label: "N4",
                            value: (row) => {
                                if (row.userprofile) {
                                    return getLevelCompany(row.userprofile.related_companies[0], 4);
                                }
                                return "";
                            },
                        },
                        {
                            label: "N5",
                            value: (row) => {
                                if (row.userprofile) {
                                    return getLevelCompany(row.userprofile.related_companies[0], 5);
                                }
                                return "";
                            },
                        },
                        {
                            label: "N6",
                            value: (row) => {
                                if (row.userprofile) {
                                    return getLevelCompany(row.userprofile.related_companies[0], 6);
                                }
                                return "";
                            },
                        },
                        {
                            label: "N7",
                            value: (row) => {
                                if (row.user && row.user.userprofile) {
                                    return getLevelCompany(row.user.userprofile.related_companies[0], 7);
                                }
                                return "";
                            },
                        },
                        {
                            label: "N8",
                            value: (row) => {
                                if (row.userprofile) {
                                    return getLevelCompany(row.userprofile.related_companies[0], 8);
                                }
                                return "";
                            },
                        },
                        {
                            label: "N9",
                            value: (row) => {
                                if (row.userprofile) {
                                    return getLevelCompany(row.userprofile.related_companies[0], 9);
                                }
                                return "";
                            },
                        },
                        {
                            label: "N10",
                            value: (row) => {
                                if (row.userprofile) {
                                    return getLevelCompany(row.userprofile.related_companies[0], 10);
                                }
                                return "";
                            },
                        },
                    ],
                    content: this.users,
                },
            ];
            let settings = { fileName: "extraction_utilisateurs" };
            console.log(this.users);
            xlsx(data, settings);
        },
        fetchUsers() {
            this.loading = true;
            userApi
                .getUsers(this.filter)
                .then((response) => {
                    this.users = response.data["users"];
                    this.totalUsers = this.users.length;
                    this.connectedUser = this.users.filter((user: any) => {
                        console.log(user);
                        console.log(user.last_connected);
                        console.log(user.last_connected.date_created);
                        if (user && user.last_connected && user.last_connected.date_created) {
                            return user;
                        }
                    }).length;
                    this.series = [Math.round((this.connectedUser * 100) / this.totalUsers)];
                    this.loading = false;
                })
                .catch(() => {
                    this.displayErrorMsg("Une erreur est survenue lors de la récupération des utilisateurs.");
                });
        },
        displayErrorMsg(msg: string) {
            Swal.fire({
                text: msg,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Fermer",
                customClass: {
                    confirmButton: "btn fw-bold btn-light-danger",
                },
            });
        },
    },

    setup() {
        const { t } = useI18n();
        return { t };
    },
    watch: {
        "$i18n.locale": function () {
            setCurrentPageBreadcrumbs(this.t("pages.dashboard.userstatistics"), []);
        },
    },
    created() {
        this.fetchUsers();
    },
});
