<template>
    <div class="row">

        <div class="col-12">
            <div class="card mb-5 mb-xxl-8" :class="{ 'animate-once': showPulse }">
                <div class="card-body pb-0">
                    <Form id="kt_add_action_form" class="form" novalidate="novalidate">
                        <div class="row mb-6">
                            <label class="col-lg-12 col-form-label required fw-bold fs-6">{{ $t("pages.ideas.ideaDesc") }}</label>
                            <div class="col-lg-12">
                                <div class="row">
                                    <div class="col-lg-12 fv-row">
                                        <textarea
                                            class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                            :placeholder="$t('pages.ideas.ideaDesc')"
                                            name="idea"
                                            v-model="this.idea.idea"
                                        ></textarea>
                                        <label class="form-check form-check-inline form-check-solid mt-5">
                                            <input class="form-check-input" name="anonymous" v-model="this.idea.anonymous" type="checkbox" />
                                            <span class="fw-bold ps-2 fs-6"> {{ $t("pages.ideas.anonymous") }} </span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
                <div class="card-footer d-flex justify-content-end py-6 px-9">
                    <button v-if="isEditMode" type="button" class="btn btn-secondary me-2" @click="cancelEdit">
                        {{ $t("general.cancel") }}
                    </button>
                    <button type="submit" id="kt_account_profile_details_submit" ref="submitButton1" class="btn btn-primary" @click="addIdea">
                        <span class="indicator-label">{{ isEditMode ? $t("general.update") : $t("general.save") }}</span>
                        <span class="indicator-progress">
                            {{ $t("general.pleaseWait") }}
                            <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    </button>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="alert alert-primary" role="alert">
                <div class="d-flex justify-content-between">
                    <p class="mt-4"><strong>
                        {{$t("pages.ideas.ideaToActionPlan") }}
                    </strong>

                    </p>
                    <button class="btn btn-primary btn-sm" @click="$router.push({ name: 'roadmap' })">
                        {{ $t("pages.ideas.toRoadmap") }}
                    </button>
                </div>
            </div>
        </div>
        <div class="col-4 mb-4" v-for="idea in this.category.ideas" :key="idea.pk">
            <div class="card bg-success bg-opacity-25 h-100">
                <div class="card-header flex-nowrap border-0 pt-9">
                    <div class="card-title w-100 d-block">
                        <div class="row">
                            <div class="col-10">
                                <i class="fas fa-lightbulb fs-3 me-3 text-gray-900"></i>
                                <a v-if="idea.anonymous" href="#" class="fs-4 fw-bold text-hover-primary text-gray-900 m-0">Anonyme</a>
                                <a v-else-if="idea.userprofile.user_type.slug == 'obea_admin'"></a>
                                <a v-else href="#" class="fs-4 fw-bold text-hover-primary text-gray-900 m-0">{{ idea.userprofile.lastname }} {{ idea.userprofile.firstname }}</a>
                                <br />
                                <i>{{ $moment(idea.date_created).format("DD/MM/YYYY") }}</i>
                            </div>
                            <div class="col-2 d-flex flex-column align-items-end">
                                <a
                                    v-if="this.$store.getters.currentUser.user.userprofile.id === idea.userprofile.id"
                                    @click="editIdea(idea)"
                                    class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm mb-2"
                                >
                                    <i class="fas fa-edit"></i>
                                </a>
                                <a
                                    v-if="this.$store.getters.currentUser.user.userprofile.id === idea.userprofile.id"
                                    @click="deleteIdea(idea)"
                                    class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                                >
                                    <i class="fas fa-trash-alt"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body d-flex flex-column pb-8">
                    <div class="d-flex align-items-center flex-wrap mb-5 fs-6">
                        <div class="text-success fw-bolder me-2">
                            {{ idea.idea }}
                        </div>
                    </div>
                    <div class="d-flex align-items-center">
                        <!-- Heart Like Button and Count -->
                        <i
                            @click="likeIdea(idea)"
                            :class="['cursor-pointer', idea.user_has_liked ? 'fas fa-heart text-danger' : 'far fa-heart']"
                            @mouseover="hover = true"
                            @mouseleave="hover = false"
                            :style="{ color: hover ? 'red' : (idea.user_has_liked ? 'red' : 'inherit') }"
                        ></i>
                        <span class="ms-2">{{ idea.like_count }}  {{ $t("pages.ideas.like") }}</span>
                    </div>
                </div>
            </div>


        </div>
    </div>
</template>

<style>
#card-content {
    display: flex;
    align-content: center;
    justify-content: space-between;
}

@keyframes pulse-yellow {
    0% {
        border-color: blue;
        box-shadow: 0 0 5px rgba(0, 00, 255, 0.8);
    }
    50% {
        border-color: transparent;
        box-shadow: 0 0 5px rgba(255, 0, 255, 0);
    }
    100% {
        border-color: blue;
        box-shadow: 0 0 5px rgba(0, 0, 255, 0.8);
    }
}

.pulse-edit {
    animation: pulse-yellow 1s infinite;
    border-width: 2px;
    border-style: solid;
}

.animate-once {
    animation: pulse-yellow 1s infinite;
    border-width: 2px;
    border-style: solid;
    animation-duration: 3s; /* Run for 3 seconds */
    animation-iteration-count: infinite; /* Repeat during animation time */
}
</style>
<script lang="ts">
import { defineComponent } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ideaBoxApi from "@/core/services/IdeaBox";
import { Form } from "vee-validate";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

interface Idea {
    pk?: number;
    idea: string;
    category: number;
    anonymous: boolean;
    userprofile?: {
        id: number;
        lastname: string;
        firstname: string;
    };
    date_created?: string;
}

export default defineComponent({
    name: "ideasdetail",
    components: { Form },
    data() {
        return {
            hover: false,
            idea: {
                idea: "",
                category: Number(this.$route.params.category_id),
                anonymous: false,
            } as Idea,
            category: {
                pk: 0,
                label: "",
            },
            isEditMode: false,
            editingIdea: null as Idea | null,
            showPulse: false, // New property to control pulsing
        };
    },
    methods: {
        likeIdea(idea){
            ideaBoxApi.likeIdea(idea.pk).then(() => {
                this.resetForm();
                this.getCategory();
            });
        },
        addIdea() {
            if (this.isEditMode && this.editingIdea) {
                ideaBoxApi.updateIdea(this.editingIdea!.pk, this.idea).then(() => {
                    this.resetForm();
                    this.getCategory();
                });
            } else {
                ideaBoxApi.addIdea(this.idea).then(() => {
                    this.resetForm();
                    this.getCategory();
                });
            }
        },
        deleteIdea(idea) {
            ideaBoxApi.deleteIdea(idea.pk).then(() => {
                this.getCategory();
            });
        },
        editIdea(idea) {
            idea.category = Number(this.$route.params.category_id);
            this.isEditMode = true;
            this.editingIdea = idea;
            this.idea = { ...idea };
            this.startPulse();
        },
        startPulse() {
            this.showPulse = true;
            setTimeout(() => {
                this.showPulse = false; // Stop the pulse after 3 seconds
            }, 3000);
        },
        cancelEdit() {
            this.resetForm();
        },
        resetForm() {
            this.isEditMode = false;
            this.editingIdea = null;
            this.idea = {
                idea: "",
                category: Number(this.$route.params.category_id),
                anonymous: false,
            };
        },
        getCategory() {
            ideaBoxApi.getCategory(this.$route.params.category_id).then((response) => {
                this.category = response.data["category"];
                setCurrentPageBreadcrumbs(this.category.label, [
                    {
                        link: "/ideas/",
                        label: this.t("pages.ideas.title"),
                    },
                ]);
            });
        },
    },
    setup() {
        const { t } = useI18n();
        const store = useStore();

        return { t };
    },
    watch: {
        "$i18n.locale": function () {
            setCurrentPageBreadcrumbs(this.category.label, [
                {
                    link: "/ideas/",
                    label: this.t("pages.ideas.title"),
                },
            ]);
        },
    },
    created() {
        this.getCategory();
    },
});
</script>
