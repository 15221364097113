
import { defineComponent } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ideaBoxApi from "@/core/services/IdeaBox";
import { Form } from "vee-validate";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

interface Idea {
    pk?: number;
    idea: string;
    category: number;
    anonymous: boolean;
    userprofile?: {
        id: number;
        lastname: string;
        firstname: string;
    };
    date_created?: string;
}

export default defineComponent({
    name: "ideasdetail",
    components: { Form },
    data() {
        return {
            hover: false,
            idea: {
                idea: "",
                category: Number(this.$route.params.category_id),
                anonymous: false,
            } as Idea,
            category: {
                pk: 0,
                label: "",
            },
            isEditMode: false,
            editingIdea: null as Idea | null,
            showPulse: false, // New property to control pulsing
        };
    },
    methods: {
        likeIdea(idea){
            ideaBoxApi.likeIdea(idea.pk).then(() => {
                this.resetForm();
                this.getCategory();
            });
        },
        addIdea() {
            if (this.isEditMode && this.editingIdea) {
                ideaBoxApi.updateIdea(this.editingIdea!.pk, this.idea).then(() => {
                    this.resetForm();
                    this.getCategory();
                });
            } else {
                ideaBoxApi.addIdea(this.idea).then(() => {
                    this.resetForm();
                    this.getCategory();
                });
            }
        },
        deleteIdea(idea) {
            ideaBoxApi.deleteIdea(idea.pk).then(() => {
                this.getCategory();
            });
        },
        editIdea(idea) {
            idea.category = Number(this.$route.params.category_id);
            this.isEditMode = true;
            this.editingIdea = idea;
            this.idea = { ...idea };
            this.startPulse();
        },
        startPulse() {
            this.showPulse = true;
            setTimeout(() => {
                this.showPulse = false; // Stop the pulse after 3 seconds
            }, 3000);
        },
        cancelEdit() {
            this.resetForm();
        },
        resetForm() {
            this.isEditMode = false;
            this.editingIdea = null;
            this.idea = {
                idea: "",
                category: Number(this.$route.params.category_id),
                anonymous: false,
            };
        },
        getCategory() {
            ideaBoxApi.getCategory(this.$route.params.category_id).then((response) => {
                this.category = response.data["category"];
                setCurrentPageBreadcrumbs(this.category.label, [
                    {
                        link: "/ideas/",
                        label: this.t("pages.ideas.title"),
                    },
                ]);
            });
        },
    },
    setup() {
        const { t } = useI18n();
        const store = useStore();

        return { t };
    },
    watch: {
        "$i18n.locale": function () {
            setCurrentPageBreadcrumbs(this.category.label, [
                {
                    link: "/ideas/",
                    label: this.t("pages.ideas.title"),
                },
            ]);
        },
    },
    created() {
        this.getCategory();
    },
});
